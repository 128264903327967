import React, { useState } from "react";
import { UpCarpet } from "../../icons";
import { userName } from "../../utils/utils";
import { useSelector } from "react-redux";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";

import Error from "../../Components/Error/Error";
const name = userName();

const TeamsSingleMsg = ({
  prompt,
  error = "",
  isLoading,
  handleRegenerate,
  requestBased,
  // socketData,
}) => {
  // // console.log("🚀 ~ socketData:", socketData);
  const userAccess = useSelector((state) => state.userAccess);
  const socketData = useSelector((state) => state.messages);

  const [bot, setBot] = useState(
    userAccess.brand === "kfc" ? kfcBot : pizzaBot
  );

  const [width] = useState(32);
  const [height] = useState(32);

  // console.log("🚀 ~ lastElement:", lastElement);

  return (
    <div className="border-0  justify-center  socketData">
      <span>
        <div className="tyn-qa-item flex gap-4 w-full">
          <div className="tyn-qa-avatar align-middle ">
            <div className="tyn-media tyn-size-md">
              <div
                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full  object-contain
              ${
                requestBased === "socket" ? "bg-socket-light" : "bg-primary-red"
              }`}
              >
                <span className="font-medium text-primary-white">{name}</span>
              </div>
            </div>
          </div>
          <div
            className={`self-center ${
              requestBased === "socket" ? "bg-socket-dark" : "bg-primary-black"
            } text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]`}
          >
            <p className="text-base font-medium">{prompt}</p>
            <span className="float-right self-center ">
              <UpCarpet width="28" height="28" />
            </span>
          </div>
        </div>
        <div className="tyn-qa-item flex gap-4 w-full mt-3">
          {requestBased !== "socket" && (
            <div className="tyn-qa-avatar">
              <div className="tyn-qa-avatar-wrap">
                <div className="tyn-media tyn-size-md">
                  <img src={bot} alt="Avatar" width={width} height={height} />
                </div>
              </div>
            </div>
          )}
          <div
            className={`ans-block self-center text-primary-black  font-normal p-2 text-sm w-full group relative ${
              requestBased === "socket" ? "flex justify-center ml-10" : ""
            }`}
            // style={{ display: "flex", justifyContent: "space-between" }}
          >
            {isLoading && (
              <div className="w-12 h-12 relative">
                <div className="absolute w-5 h-5 bg-primary-black rounded-full opacity-70 animate-ping"></div>
                <div className="w-5 h-5 bg-primary-black rounded-full"></div>
              </div>
            )}

            {error && (
              <>
                <Error msg={error.error ? error.error : error.data.detail} />
                {handleRegenerate && (
                  <div className="text-center flex justify-center">
                    <button
                      onClick={() => handleRegenerate()}
                      type="button"
                      className="text-center  mt-5 text-primary-white bg-primary-red hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    >
                      Regenerate
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};

export default TeamsSingleMsg;
