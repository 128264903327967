import React, { useEffect, useState } from "react";
import TeamsSidebar from "./TeamsSidebar";
import TeamsMessageWrapper from "./TeamsMsgs";
import { useGetTeamsConversationsQuery } from "../../services/teamsConversations";
import { useLocation } from "react-router-dom";

const Teams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("id") ? params.get("id") : 123;
  console.log("🚀 ~ Teams ~ myParam:", myParam);

  const [sidebarData, setSidebarData] = useState([]);
  const { data, error, isFetching, refetch } =
    useGetTeamsConversationsQuery(myParam);

  const [newChat, setNewChat] = useState();
  const [showWelcome, setShowWelcome] = useState(
    isFetching && data?.conversations && data.conversations?.length === 0
      ? true
      : false
  );

  useEffect(() => {
    if (data && data.conversations) {
      if (!sidebarData.conversations) {
        setSidebarData(data);
      } else if (
        sidebarData.conversations.length !== data.conversations.length
      ) {
        setSidebarData(data);
      }
      setShowWelcome(
        isFetching && data?.conversations && data.conversations?.length === 0
          ? true
          : false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <TeamsSidebar
        data={sidebarData.conversations}
        error={error}
        isFetching={isFetching}
        newChat={newChat}
        setNewChat={setNewChat}
        setShowWelcome={setShowWelcome}
      />
      <TeamsMessageWrapper
        data={data}
        error={error}
        isFetching={isFetching}
        newChat={newChat}
        setNewChat={setNewChat}
        showWelcome={showWelcome}
        myParam={myParam}
      />
    </>
  );
};

export default Teams;
