import { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { current } from "@reduxjs/toolkit";
import { teamsConversationsApi } from "../services/teamsConversations";
import { userEmail } from "../utils/utils";

const useTeamsMessageHandler = (
  lastMessage,
  selectedConversation,
  myParam,
  appDispatch,
  setMsgCounter,
  setSocketConversationStarted,
  setPromt,
  msgCounterRef,
  questionIdRef,
  newCoversation
) => {
  const dispatch = useDispatch();

  const handleTeamsNewConversation = useCallback(
    (parsedData) => {
      const { conversation_id, content, name, role, initial_prompt } =
        parsedData;

      appDispatch(
        teamsConversationsApi.util.updateQueryData(
          "getTeamsConversations",
          myParam ? myParam : "",
          (draftPosts) => {
            const prompt = localStorage.getItem("currentPrompt");
            localStorage.removeItem("currentPrompt");
            const email = userEmail();

            const userPrompt = {
              content: prompt,
              feedback: { vote: 0, comment: "" },
              name: "user_proxy",
              role: "assistant",
            };

            const newConversations = {
              _id: conversation_id,
              conversation_name: prompt,
              app_uuid: myParam,
              object: "team",
              object_uuid: 123,
              yum_id: email,
              chat_history: [
                userPrompt,
                {
                  content,
                  name,
                  role,
                  feedback: { vote: 0, comment: "" },
                  conversation_id,
                  initial_prompt,
                },
              ],
              deleted: false,
            };
            const conversationExists = draftPosts.conversations.some(
              (conversation) => {
                console.log("🚀 ~ conversation:", conversation);
              }
            );

            if (!conversationExists) {
              draftPosts.conversations.unshift(newConversations);
              setSocketConversationStarted(false);
              setMsgCounter(0);
              setPromt();
            } else {
              console.log(
                "Conversation with conversation_id already exists:",
                conversation_id
              );
            }
          }
        )
      );
    },
    [
      appDispatch,
      myParam,
      setMsgCounter,
      setPromt,
      setSocketConversationStarted,
    ]
  );

  const handleTeamsSocketHistory = useCallback(
    (parsedData, selectedConversation) => {
      let { conversation_id, content, name, role, initial_prompt } = parsedData;
      conversation_id = conversation_id
        ? conversation_id
        : selectedConversation;
      const checkAndUpdateCache = () => {
        const state = appDispatch((_, getState) => getState());
        const existingCache =
          teamsConversationsApi.endpoints.getTeamsConversations.select(myParam)(
            state
          );

        console.log("🚀 ~ checkAndUpdateCache ~ existingCache:", existingCache);

        if (existingCache && existingCache.data) {
          const data = existingCache.data;
          appDispatch(
            teamsConversationsApi.util.updateQueryData(
              "getTeamsConversations",
              myParam,
              (draftPosts) => {
                if (draftPosts) {
                  const copy = current(draftPosts);
                  console.log(copy, "copy");

                  const prompt = localStorage.getItem("currentPrompt");
                  localStorage.removeItem("currentPrompt");

                  const updated = copy.conversations.map((item) => {
                    const newObj = { ...item };

                    if (newObj._id === conversation_id) {
                      let existingHistory = [...newObj.chat_history];
                      const userPrompt = {
                        content: prompt,
                        feedback: { vote: 0, comment: "" },
                        name: "user_proxy",
                        role: "assistant",
                      };

                      existingHistory.push(userPrompt);

                      existingHistory.push({
                        content,
                        name,
                        role,
                        feedback: { vote: 0, comment: "" },
                        conversation_id,
                        initial_prompt,
                      });
                      newObj.chat_history = existingHistory;
                    }
                    return newObj;
                  });
                  draftPosts.conversations = updated;
                  setSocketConversationStarted(false);
                  setMsgCounter(0);
                  setPromt();
                } else {
                  console.warn("No draftPosts available to update.");
                }
              }
            )
          );
        }
      };

      checkAndUpdateCache();
    },
    [
      appDispatch,
      myParam,
      setMsgCounter,
      setPromt,
      setSocketConversationStarted,
    ]
  );

  useEffect(() => {
    if (lastMessage) {
      console.log("🚀 ~ useEffect ~ lastMessage:", lastMessage);

      const { data } = lastMessage;
      const parsedData = JSON.parse(data);
      console.log("🚀 ~ useEffect ~ parsedData:", parsedData);

      if (parsedData.content) {
        if (newCoversation) {
          console.log("New Conversation");
          handleTeamsNewConversation(parsedData);
        } else if (selectedConversation) {
          console.log("Adding to conversation history");

          handleTeamsSocketHistory(parsedData, selectedConversation);
        }
      } else if (parsedData.error) {
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, handleTeamsNewConversation, handleTeamsSocketHistory]);
};

export default useTeamsMessageHandler;
